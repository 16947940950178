<template>
    <b-form-group :label="label" :invalid-feedback="errorTxt" :state="state" class='input-group-sm'>
        <template v-if="required" #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <multiselect v-model="selected" :custom-label="getLabel" track-by="id" :placeholder="`Chọn ${modelParam.label.toLowerCase()}`"
                     :options="items" :multiple="multiple"
                     @input="onInput" @search-change="search"
                     select-label="Chọn" deselect-label="Xoá" selected-label="Đã chọn">
            <template v-slot:noResult>
                Không tìm thấy kết quả nào
            </template>
            <template v-slot:noOptions>
                Không tìm thấy kết quả nào
            </template>
        </multiselect>
        <template v-if="desc">
            <small v-if="desc" class="form-text text-muted">{{desc}}</small>
        </template>
    </b-form-group>
</template>

<script>
    import _ from "lodash";
    import list from '@/mixins/List';
    import Multiselect from 'vue-multiselect';

    export default {
        name: "parameterSelect",
        props: {
            type: String,
            desc: String,
            model: Object,
            filter: Object,
            attribute: String,
            label: String,
            errors: Object,
            multiple: Boolean,
            isFilter: Boolean,
            required: Boolean,
        },
        components: {Multiselect},
        mixins: [list],
        data() {
            return {
                selected: null,
                state: null,
                errorTxt: '',
                options: [],
                showList: false,
                service: '/parameter',
                autoLoad: false,
                defaultFilterFormModel: {
                    keyword: '',
                    type: this.type
                },
                focused: false,
                changeTrigger: true
            };
        },
        computed: {
            modelParam() {
                return Object.values(this.$params.const).find(item => {
                    return item.key === this.type;
                });
            },
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    if (val[this.attribute] && val[this.attribute] !== null) {
                        if (this.changeTrigger) {
                            this.loadSelected();
                        }
                        this.changeTrigger = true;
                    } else {
                        this.selected = null;
                    }
                }, deep: true
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        methods: {
            load() {
                let loadingDelay = _.delay(() => {
                    this.listLoading = true;
                }, 250);

                let filter = Object.assign({}, this.filterFormModel);
                if (this.filter) {
                    filter = Object.assign(this.filter, filter);
                }
                Object.keys(filter).forEach(key => {
                    if (typeof filter[key] === 'object' && filter[key]) {
                        filter[key] = filter[key].join(",");
                    }
                });
                this.$service.get(this.service, {
                    params: filter
                }).then(response => {
                    this.firstLoading = false;
                    this.apply(response.data);
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    clearTimeout(loadingDelay);
                    this.listLoading = false;
                });
            },
            onInput() {
                let val = this.selected;
                this.changeTrigger = false;
                if (val === null) {
                    this.model[this.attribute] = val;
                } else if (this.multiple) {
                    this.model[this.attribute] = val.map(s => s.code);
                } else {
                    this.model[this.attribute] = val.code;
                }
                this.$emit('change');
            },
            getLabel( {value}) {
                return value;
            },
            loadSelected: function () {
                if (this.model[this.attribute]) {
                    let ids;
                    if (this.multiple) {
                        ids = this.model[this.attribute].filter(i => i);
                    } else {
                        ids = [];
                        ids.push(this.model[this.attribute].toString());
                    }
                    if (ids) {
                        this.$service.get(`${this.service}/gets`, {
                            params: {codes: ids.join(","), type: this.type}
                        }).then(response => {
                            if (this.multiple) {
                                this.selected = response.data;
                            } else if (response.data.length) {
                                this.selected = response.data[0];
                            }
                        });
                    }
                } else if (!this.multiple) {
                    if (this.isFilter) {
                        this.selected = {id: null, value: `Tất cả ${this.modelParam.label.toLowerCase()}`};
                    } else {
                        this.selected = null;
                    }
                }
            },
            apply(response) {
                this.items = [];
                if (!this.multiple) {
                    if (this.isFilter) {
                        this.items.push({id: null, value: `Tất cả ${this.modelParam.label.toLowerCase()}`});
                    }
                }
                this.items = this.items.concat(response.data);
                this.itemCount = response.dataCount;
                this.firstItemOfPage = this.itemCount > 0 ? (this.filterFormModel.page - 1) * this.filterFormModel.pageSize + 1 : 0;
                this.lastItemOfPage = (this.filterFormModel.page - 1) * this.filterFormModel.pageSize + this.items.length;
            }
        },
        mounted() {
            this.selected = null;
            this.loadSelected();
            this.load();
        }
    }
</script>