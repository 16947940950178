<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";
    import moment from 'moment';

    export default {
        props: ['filter', 'totalCount', 'params', 'types', 'relationshipOptions','prime'],
        mixins: [Export],
        data() {
            let headers = [
                    {header: 'code_local', key: 'code_local',label: 'Mã định danh', width: 30},
                    {header: 'code', key: 'code', label: 'Mã số thành viên gia đình', width: 26},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên', width: 17},
                    {header: 'dob', key: 'dob', label: 'Năm sinh', width: 12},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành Phố', width: 29},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 15},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường', width: 15},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính', width: 16},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 12},
                    {header: 'relation', key: 'relation', label: 'Mối quan hệ với NKT', width: 24},
                    // {header: 'type', key: 'type', label: 'Loại hình hỗ trợ được nhận ', width: 21},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu can thiệp/hỗ trợ', width: 20},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày can thiệp gần nhất', width: 20},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc can thiệp/hỗ trợ', width: 20},
                    {header: 'result', key: 'result', label: 'Kết quả đánh giá', width: 20},
                    {header: 'patient_code', key: 'patient_code', label: 'Mã số NKT được chăm sóc', width: 22},
                ];
            if (this.prime) {
                headers.unshift({header: 'client_id', key: 'client_id', label: 'Đơn vị', width: 20});
            }
            return {
                service: '/patient-relative',
                fileName: `can-thiep-tam-ly-nguoi_than`,
                headers,
            }
        },
        methods: {
            setupSheet: function (sheet) {
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);


                let provinces = [];
                var tb = this.params.provinceImport;
                for (const key in tb) {
                    provinces.push(tb[key]);
                }


                row = {};
                if(this.prime){
                    row['client_id'] = "Nhập định dạng chữ";
                }
                row["code_local"] = "Mã định danh"
                row["code"] = "Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)"
                row["fullname"] = "Nhập định dạng chữ (tối đa 200 ký tự)";
                row["dob"] = "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu) (yyyy)";
                row["province_id"] = "Nhập định dạng chữ (" + provinces.toString() + ")";
                row["district_id"] = "Nhập định dạng chữ";
                row["ward_id"] = "Nhập định dạng chữ";
                row["gender_id"] = "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)";
                row["race_id"] = "Nhập định dạng chữ";
                row["relation"] = "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: " + this.relationshipOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                // row["type"] = "Multiple choice (" + this.types.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row["patient_code"] = "Nhập định dạng chữ";
                row["start_time"] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu";
                row["rate_time"] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp";
                row["end_time"] = "Nhập định dạng ngày/tháng/năm (dd/mm/yyyy)";
                row["result"] = "Nhập 01 giá trị số từ 1 đến 3 (Trong đó: " + this.$params.patient.resultOfEvaluation.filter(item => item.value != null).map(item => item.text).toString() + ")";
                
                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };
            },
            addRow(item, params) {
                let row = {
                    code: item.code,
                    code_local: item.code_by_local ? item.code_by_local.toString() : '',
                    fullname: item.fullname,
                    dob: item.dob != null || item.dob != 0 ? item.dob : '',
                    province_id: params.provinces && params.provinces[item.province_id] ? params.provinces[item.province_id].name : '',
                    district_id: params.districts && params.districts[item.district_id] ? params.districts[item.district_id] : '',
                    ward_id: params.wards && params.wards[item.ward_id] ? params.wards[item.ward_id] : '',
                    gender_id: item.gender_id != null ? this.$params.gender.find(o => o.value == parseInt(item.gender_id)).text : '',
                    race_id: item.race_id ? this.params.race[item.race_id] : '',
                    relation: item.relation && this.relationshipOptions.find(o => o.value == item.relation) ? this.relationshipOptions.find(o => o.value == item.relation).text : "",
                    patient_code: params.patient_id[item.patient_id] ? params.patient_id[item.patient_id].code : '',
                };
                if(params.stages[item.id].length > 0){
                    row['start_time'] = params.stages[item.id][0].start_time > 0 ? moment(params.stages[item.id][0].start_time).format('DD/MM/YYYY') : '';
                    row['rate_time'] = params.stages[item.id][0].rate_time > 0 ? moment(params.stages[item.id][0].rate_time).format('DD/MM/YYYY') : '';
                    row['end_time'] = params.stages[item.id][0].end_time > 0 ? moment(params.stages[item.id][0].end_time).format('DD/MM/YYYY') : '';
                    // row['type'] = this.getTypes(params.stages[item.id][0].types);
                    row['result'] = params.stages[item.id][0].result && this.$params.patient.resultOfEvaluation.find(o => o.value == params.stages[item.id][0].result) ? this.$params.patient.resultOfEvaluation.find(o => o.value == params.stages[item.id][0].result).text : '';
                }
                if(this.prime){
                    row['client_id'] = params.clients[item.client_id].short_name;
                }
                return row;
            },
            getTypes(type) {
                let text = "";
                if (type != null) {
                    for (let item of type) {
                        if (this.types.find(o => o.value == item)) {
                            text = text + this.types.find(o => o.value == item).text + "; ";
                        }

                    }
                }
                return text;
            },
        }
    }
</script>