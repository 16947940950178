import {saveAs} from 'file-saver'
import Excel from 'exceljs';

export default {
    data: function () {
        return {
            service: '/',
            reloadBus: null,
            show: false,
            file: null,
            reading: false,
            importing: false,
            updated: 0,
            totalRows: 0,
            // indexRow: 4,
            successCount: 0,
            errorCount: 0,
            importErrors: [],
            cancelRequest: false,
            templateName: 'template.xlsx',
        }
    },
    methods: {
        downloadTemplate: function () {
            let workbook = new Excel.Workbook();
            let sheet = workbook.addWorksheet('Dữ liệu');
            let cols = [];
            let row = {};
            let row_desc = {};
            for (let i in this.headers) {
                let header = this.headers[i];
                cols.push(header);
                row[header.key] = header.label;
                row_desc[header.key] = header.desc;
            }
            sheet.columns = cols;
            console.log(cols);
            sheet.addRow(row);
            sheet.addRow(row_desc);
            row.alignment = {wrapText: true};
                //ẩn dòng 1
            sheet.getRow(1).hidden = true;
            sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            sheet.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'bdbdbd'
                }
            };
            // đôi đậm và wrap text
            sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            sheet.getRow(2).font = {
                bold: true
            };
            //bôi màu
            sheet.getRow(2).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '92D050'
                }
            };
            //Dòng 3
            sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            sheet.getRow(3).font = {
                italic: true
            };
            workbook.xlsx.writeBuffer({base64: true}).then(data => {
                saveAs(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                        this.templateName);
            });
        },
    }
};