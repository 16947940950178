import {saveAs} from 'file-saver';
import Excel from 'exceljs';
import moment from 'moment';

export default {
    data: function () {
        return {
            service: '/',
            fileName: 'tap_tin',
            headers: [{key: '', name: ''}],
            exporting: false,
            downloadedCount: 0
        }
    },
    methods: {
        close(evt) {
            if (this.exporting) {
                evt.preventDefault();
            } else {
                this.exporting = false;
            }
            this.downloadedCount = 0;
        },
        setupSheet: function (sheet) {
            let cols = [];
            let row = {};
            for (let i in this.headers) {
                let header = this.headers[i];
                cols.push(header);
                row[header.key] = header.label;
            }
            sheet.columns = cols;
            sheet.addRow(row);
        },
        exportFile: async function () {
            this.exporting = true;
            this.downloadedCount = 0;
            let workbook = new Excel.Workbook();
            let sheet = workbook.addWorksheet('data');
            await this.setupSheet(sheet);

            let response = null;
            let params = Object.assign({}, this.filter);
            Object.keys(params).forEach(key => {
                if (typeof params[key] === 'object' && params[key]) {
                    params[key] = params[key].join(",");
                }
            });
            params.page = 1;
            params.size = 50;
            do {
                let result = await this.$service.get(`${this.service}/index`, {
                    params: params
                });
                params.page += 1;
                response = result.data;
                for (let index in response.data) {
                    let item = response.data[index];
                    let row = {};
                    if (this.addRow) {
                        row = this.addRow(item, response.params, index, sheet);
                    }
                    sheet.addRow(row);
                    this.downloadedCount += 1;
                }
            } while (response && response.page < response.pageCount);

            workbook.xlsx.writeBuffer({base64: true}).then(data => {
                this.exporting = false;
                this.downloadedCount = 0;
                let fileName = this.fileName + '_' + moment().format('HHmm_DDMMYYYY');
                if (this.getName) {
                    fileName = this.getName() + '_' + moment().format('HHmm_DDMMYYYY');
                }

                saveAs(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                        `${fileName}.xlsx`);
            });
        }
    }
};