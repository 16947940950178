<template>
    <button class="btn btn-outline-gray btn-sm" @click="open">
        <b-spinner v-if="listLoading" small></b-spinner>
        <i v-else class="fa fa-comment" ></i>
        <span v-if='title' > {{title}}</span>
        <span v-else> Ghi chú</span>

        <b-modal v-model="listForm" size="lg" content-class="modal-auto" :title="title" ok-only ok-variant="secondary" ok-title="Đóng" no-close-on-backdrop no-enforce-focus>
            <div class="row">
                <div class="col-12">
                    <g-loading v-if="firstLoading" />
                    <div v-else-if="listLoading" class="text-center text-primary">
                        <b-spinner></b-spinner>
                    </div>
                    <div v-else-if="!listLoading && !itemCount" class="text-center text-danger">
                        Không tìm thấy ghi chú nào
                    </div>
                    <div v-else-if="!listLoading && itemCount" class="title-template-table  table-responsive">
                        <div class="row mb-2" v-if="itemCount > filterFormModel.size">
                            <div class="col-6" >
                                Đang xem {{firstItemOfPage | numFormat}}-{{lastItemOfPage | numFormat}}/{{itemCount | numFormat}} lịch sử
                            </div>
                            <div class="col-6" >
                                <b-pagination size="sm" class="pagination pagination-sm float-right pb-0 mb-0"
                                              v-model="filterFormModel.page" :per-page="filterFormModel.size" :total-rows="itemCount" @change="setPage">
                                </b-pagination>
                            </div>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Thời gian</th>
                                    <th>Nhân viên</th>
                                    <th>Nội dung</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in items" :key="item.id" >
                                    <td>{{item.create_at * 1000 | datetimeFormat}}</td>
                                    <td>
                                        <span v-if="params.staffs && params.staffs[item.staff_id]" >
                                            {{isStaff || item.staff_id === $store.state.user.id  ? params.staffs[item.staff_id] : "* * * * *"}}
                                        </span>
                                        <span v-else >Hệ thống</span>
                                    </td>
                                    <td v-html="item.content"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>    
            <template v-slot:modal-footer>
                <div class="input-group input-group-sm">
                    <input v-model="formModel.content" type="text" class="form-control" v-on:keyup="enter" placeholder="Enter để lưu nội dung cần thêm" />
                    <div class="input-group-append">
                        <button class="btn btn-gray" type="button" @click="save">Thêm ghi chú</button>
                        <button class="btn btn-secondary" @click="listForm = false">Đóng</button>
                    </div>
                </div>
            </template>
        </b-modal>
    </button>
</template>

<script>

    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    export default {
        props: ["type", "targetId", "title", "isStaff"],
        mixins: [list, crud],
        components: {},
        data() {
            return {
                autoLoad: false,
                listForm: false,
                service: '/log',
                defaultFilterFormModel: {
                    type: this.type,
                    target_id: this.targetId,
                    size: 15
                },
                defaultFormModel: {
                    type: this.type,
                    target_id: this.targetId,
                    content: ""
                }
            };
        },
        methods: {
            save: function (evt) {
                if (evt) {
                    evt.preventDefault();
                }
                this.saveLoading = true;
                let method = 'post';
                let url = this.service;
                if (this.formModel.id) {
                    method = 'put';
                    url += `/update?id=${this.formModel.id}`;
                } else {
                    method = 'post';
                    url += '/create';
                }
                if (this.beforeSave) {
                    this.beforeSave();
                }
                return this.$service({url: url, method: method, data: this.formModel}).then(response => {
                    this.showForm = false;
                    if (this.load) {
                        this.load();
                    }
                    if (this.reloadBus) {
                        this.$bus.$emit(this.reloadBus);
                    }
                    if (response.data) {
                        this.$swal({
                            title: "Thành công",
                            text: this.formModel.id ? 'Chỉnh sửa thành công' : 'Thêm mới thành công',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000,
                        });
                        this.formModel.content = "";
                    } else {
                        this.$swal({
                            title: "Có lỗi",
                            text: this.formModel.id ? 'Chỉnh sửa thất bại' : 'Thêm mới thất bại',
                            icon: 'error',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000,
                        });
                    }
                    return response.data;
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                        this.showForm = false;
                    } else {
                        this.$swal({
                            text: "Vui lòng kiểm tra lại thông tin",
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                        });
                        this.formErrors = error.response.data;
                    }
                }).finally(() => {
                    this.saveLoading = false;
                });
            },
            enter: function (e) {
                if (e.keyCode === 13) {
                    this.save();
                }
            },
            applyList() {
                this.create();
            },
            open() {
                this.filterFormModel.target_id = this.targetId;
                this.formModel.target_id = this.targetId;
                this.listForm = true;
                this.load();
            }

        }
    };
</script>
