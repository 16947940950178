<template>
    <button @click="show = true" class="btn ml-2 btn-success btn-sm">
        <i class="fa fa-upload"></i> Tải dữ liệu
        <b-modal v-model="show" title="Tải danh sách người thân" @close="close" no-close-on-esc no-close-on-backdrop
                 no-enforce-focus>
            <!-- <div class="form-group">
                <label><b>Tải xuống tập tin mẫu</b></label>
                <div>
                    <button @click="downloadTemplate" class="btn btn-sm btn-secondary mr-1 mb-2">
                        <i class="fa fa-file-excel"></i> {{templateName}}
                    </button>
                </div>
            </div> -->
            <div class="form-group">
                <label><b>Chọn tập tin</b></label>
                <b-form-file v-model="file" :disabled="reading||totalRows>0"
                             placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             drop-placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             browse-text="Chọn tập tin" 
                             accept="application/x-iwork-keynote-sffnumbers,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
            </div>
            <b-form-checkbox v-model="updated" value="1" unchecked-value="0">
                Cho phép cập nhật thông tin khi trùng mã số người thân, ngày bắt đầu can thiệp, ngày can thiệp gần nhất
            </b-form-checkbox>
            <div class='mb-3' ></div>
            <div v-if="reading" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang đọc file. Quá trình này có thể sẽ tốn nhiều thời gian nếu file tải lên có dung lượng lớn. Xin đừng tắt cửa sổ trình duyệt cho đến khi quá trình tải lên hoàn tất!</span>
            </div>
            <div v-if="importing" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang tải dữ liệu lên... Xin hãy kiên nhẫn, việc tải lên sẽ mất một chút thời gian nếu file của bạn quá lớn</span>
            </div>
            <div class="mb-3" v-if="totalRows">
                <b-progress :max="totalRows">
                    <b-progress-bar :value="successCount+errorCount"
                                    :label="`${(((successCount + errorCount) / totalRows) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
                <div class="text-center mt-2">Xử lý {{ successCount + errorCount }}/{{ totalRows }} dòng | <span :class="[errorCount && errorCount > 0? 'text-danger':'']" >Vấn đề: {{ errorCount }} </span>
                    <a v-if='errorCount > 0' @click="downloadErrors" class="btn-link text-primary" href="javascript:;">[Chi tiết]</a>
                </div>
            </div>
            <template v-slot:modal-footer>
                <button class="btn btn-secondary" @click="cancel" v-if="!reading&&importing">Dừng tải</button>
                <button class="btn btn-primary" @click="reset" v-if="!reading&&!importing&&totalRows>0">Làm lại</button>
                <button v-if="totalRows===0" class="btn btn-primary" @click="process" :disabled="reading||importing">
                    <b-spinner small v-if="reading||importing"></b-spinner>
                    Tải lên
                </button>
            </template>
        </b-modal>
    </button>
</template>

<script>
    import Import from "@/mixins/Import";

    export default {
        props: ['client_id', 'params', 'types', 'relationshipOptions'],
        mixins: [Import],
        data() {
            return {
                reloadBus: "reloadPatientRelative",
                service: '/patient-relative',
                templateName: 'can-thiep-tam-ly-nguoi_than.xlsx',
                headers: [
                    {header: 'code', key: 'code', label: 'Mã số thành viên gia đình*', width: 26, desc: 'Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)'},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 17, desc: 'Nhập định dạng chữ (tối đa 200 ký tự)'},
                    {header: 'dob', key: 'dob', label: 'Năm sinh*', width: 12, desc: 'Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)'},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 29, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 20, desc: "Nhập định dạng chữ"},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 20, desc: "Nhập định dạng chữ"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 16, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 12, desc: "Nhập định dạng chữ"},
                    {header: 'relation', key: 'relation', label: 'Mối quan hệ với NKT', width: 24, desc: "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: " + this.$params.patientRelative.relationshipOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    // {header: 'type', key: 'type', label: 'Loại hình hỗ trợ được nhận * ', width: 21},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu can thiệp/hỗ trợ *', width: 20, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu"},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày can thiệp gần nhất *', width: 20, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc can thiệp/hỗ trợ', width: 20, desc: "Nhập định dạng ngày/tháng/năm (dd/mm/yyyy)"},
                    {header: 'result', key: 'result', label: 'Kết quả đánh giá', width: 20, desc: "Nhập 01 giá trị số từ 1 đến 3 (Trong đó: " + this.$params.patient.resultOfEvaluation.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'patient_code', key: 'patient_code', label: 'Mã số NKT được chăm sóc', width: 22, desc: "Nhập định dạng chữ"},
                ],
                indexRow: 4,
            }
        },
        methods: {
            async save(item) {
                if (this.updated == 1) {
                    await this.$service.post(`${this.service}/import?t=u`, item);
                } else {
                    await this.$service.post(`${this.service}/import?t=c`, item);
                }
            },
            // process(evt) {
            //     evt.preventDefault();
            //     if (!this.file) {
            //         this.$swal({
            //             title: "Thông báo",
            //             text: "Vui lòng chọn tập tin cần tải lên hệ thống",
            //             icon: 'error',
            //         });
            //         return;
            //     }
            //     if (this.file.name.endsWith('.xls') || this.file.name.endsWith('.xlsx')) {
            //         this.reading = true;
            //         let readStream = fileReaderStream(this.file);
            //         const workbook = new Excel.Workbook();
            //         workbook.xlsx.read(readStream).then(async () => {
            //             if (workbook.worksheets.length === 0) {
            //                 throw new Error("File tải lên chưa đúng mẫu!");
            //             }
            //             let sheet = workbook.worksheets[0];
            //             if (sheet.actualRowCount < this.indexRow) {
            //                 throw new Error("File tải lên chưa đúng mẫu!fc");
            //             }
            //             if (this.validate) {
            //                 this.validate(sheet);
            //             }
            //             let headerRow = sheet.getRow(1);
            //             let i = this.indexRow;
            //             this.totalRows = sheet.actualRowCount - 3;
            //             this.reading = false;
            //             this.importing = true;
            //             if (this.totalRows === 0) {
            //                 throw new Error("File tải lên không có dòng dữ liệu nào!");
            //             }
            //             while (i <= sheet.actualRowCount) {
            //                 if (this.cancelRequest) {
            //                     break;
            //                 }
            //                 let item = await this.getItem(headerRow, sheet.getRow(i));
            //                 try {
            //                     if (this.save) {
            //                         await this.save(item);
            //                     } else {
            //                         await this.$service.post(`${this.service}/import`, item);
            //                     }
            //                     this.successCount++;
            //                 } catch (err) {
            //                     this.importErrors.push({row: i, err: err});
            //                     this.errorCount++;
            //                 }
            //                 i++;
            //             }
            //         }).catch(err => {
            //             this.$swal({
            //                 title: "Thông báo",
            //                 text: err.message,
            //                 icon: 'error'
            //             });
            //         }).finally(() => {
            //             this.reading = false;
            //             this.importing = false;
            //             this.cancelRequest = false;
            //             if (this.reloadBus) {
            //                 this.$bus.$emit(this.reloadBus);
            //             }
            //         });
            //     } else {
            //         this.$swal({
            //             title: "Thông báo",
            //             text: "File tải lên không đúng định dạng!",
            //             icon: 'error',
            //         });
            //     }
            // },
            async getItem(headerRow, row) {
                let vm = this;
                let item = {
                    client_id: this.client_id
                };
                for (let j = 1; j <= headerRow.cellCount; j++) {
                    let key = headerRow.getCell(j).value;
                    let value;
                    if (row.getCell(j).isHyperlink) {
                        value = row.getCell(j).value.text;
                    } else {
                        value = row.getCell(j).value;
                    }

                    if (headerRow.getCell(1).value !== 'code' || headerRow.getCell(2).value !== 'fullname' || headerRow.getCell(9).value !== 'relation') {
                        throw new Error("File tải lên chưa đúng mẫu!");
                    }

                    if (value !== null && value !== '') {
                        if (typeof value === 'string') {
                            value = value.trim();
                        }
                        if (['gender_id'].includes(key)) {
                            let gender_id = vm.$utils.createAlias(value);
                            item.gender_id = gender_id == ('nam' || 0) ? 0 : gender_id == ('nu' || 1) ? 1 : 2;
                        } else if (['race_id'].includes(key)) {
                            let idRs = [];
                            let nameRS = [];
                            const RaceS = {};
                            for (let i in this.params.race) {
                                let r = this.params.race[i];
                                idRs.push(i);
                                let nameRaceS = vm.$utils.createAlias(r);
                                nameRS.push(nameRaceS);
                                RaceS[nameRaceS] = i;
                            }
                            if (idRs.includes(value)) {
                                item.race_id = value + '';
                            } else if (nameRS.includes(vm.$utils.createAlias(value))) {
                                item.race_id = RaceS[vm.$utils.createAlias(value)];
                            } else {
                                item.race_id = -1;
                            }
                        } else if (['fullname'].includes(key)) {
                            item[key] = value + "";
                        } else if (['code'].includes(key)) {
                            item.code = value.toString().toUpperCase();
                        } else if (['patient_code'].includes(key)) {
                            item.patient_code = value.toString().toUpperCase();
                        } else if (['start_time'].includes(key)) {
                            value = this.getDate(value, "DD/MM/YYYY");
                            item.start_time = value;
                        } else if (['end_time'].includes(key)) {
                            value = this.getDate(value, "DD/MM/YYYY");
                            item.end_time = value;
                        } else if (['rate_time'].includes(key)) {
                            value = this.getDate(value, "DD/MM/YYYY");
                            item.rate_time = value;
                        // } else if (['type'].includes(key)) {
                        //     let result = [];
                        //     let key = this.getKeyOption(this.$params.patientRelative.typeOptions);
                        //     if (value.toString().includes(",")) {
                        //         let array = value.split(',');
                        //         array.forEach((entry) => {
                        //             if (key.includes(entry.trim())) {
                        //                 result.push(entry.trim());
                        //             } else {
                        //                 result.push(-1);
                        //             }
                        //         });
                        //     } else {
                        //         if(key.includes(value.toString())){
                        //             result.push(value.toString());
                        //         }   else {
                        //             result.push(-1);
                        //         }
                        //     }
                        //     item.type = result;
                        } else if (['result'].includes(key)) {
                            let key = this.getKeyOption(this.$params.patient.resultOfEvaluation);
                            if(value && value != ''){
                                if(key.includes(value.toString().trim())){
                                    item.result = value.toString().trim()
                                } else {
                                    item.result = -1;
                                }
                            }
                        } else if (['relation'].includes(key)) {
                            let key = this.getKeyOption(this.$params.patientRelative.relationshipOptions);
                            if(value && value != ''){
                                if(key.includes(value.toString().trim())){
                                    item.relation = value.toString().trim()
                                } else {
                                    item.relation = -1;
                                }
                            }
                        } else {
                            item[key] = value;
                        }
                    } else {
                        item[key] = null;
                    }

                }
                console.log(item);
                return item;
            }
        }
    }
</script>
