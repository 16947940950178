<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :placeholder="'Nhập mã, họ tên ...'" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Can thiệp tâm lý người thân" titleIcon="fa fa-user-injured">
                <template slot="filter">
                    <client-select v-if='isPrime() || isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='searchClient' />
                    <client-select v-if='(isTW() ||  isUsaid()) && filterFormModel.client_id' attribute="client_sub_id"  :model="filterFormModel" :errors="formErrors" placeHolder="Chọn đơn vị thầu phụ"  :filter="{parent_id: filterFormModel.client_id}" :is-filter='true' class="mr-2" @change='search' />
                    <patient-select v-if='!showFormPatient' :model="filterFormModel" attribute="patient_id"  :is-filter='true' :filter="{client_id: filterFormModel.client_id}"  class="mr-2"  @change='search' />
                    <province-select :model="filterFormModel" attribute="province_id"  :is-filter='true'  class="mr-2"  @change='search' />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="relation"  :options="$params.patientRelative.relationshipOptions" :isFilter="true" @change="search" />
                </template>
                <template slot="action">
                    <export :filter="filterFormModel" :prime="isPrime() || isTW() || isUsaid()" :params="params" :totalCount="itemCount" class="mr-1" :types="$params.patientRelative.typeOptions" :relationshipOptions="$params.patientRelative.relationshipOptions"/>
                    <g-button class="ml-1 btn btn-gray btn-sm" icon="fa fa-file-excel" v-if="isSub()" :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                    <import :client_id="params.clientId" :params='params' class="mr-1" v-if="isSub()" :types="$params.patientRelative.typeOptions" :relationshipOptions="$params.patientRelative.relationshipOptions"/>
                    <g-button class="ml-2" v-if="permissions('patient_create') && isSub()" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(in)="{item}">
                    <div v-if="item.patient_id && params.patient_id[item.patient_id]"><span class="small text-gray-999">Mã: </span> 
                        <span>{{ params.patient_id[item.patient_id].code}}</span>
                    </div>
                    <div v-if="item.patient_id && params.patient_id[item.patient_id]"><span class="small text-gray-999">Họ và tên: </span> 
                        <span>{{ params.patient_id[item.patient_id].fullname}}</span>
                    </div>
                </template>
                <template #cell(fullname)="{item}">
                    <div><span class="small text-gray-999">Mã: </span><span>{{ item.code }}</span></div>
                    <div v-if="item.fullname"><span class="small text-gray-999">Họ và tên: </span><span>{{ item.fullname }}</span></div>
                    <div v-if="item.relation" ><span class="small text-gray-999">Mối quan hệ với NKT: </span> 
                        <span   v-if='$params.patientRelative.relationshipOptions.find(o => o.value === item.relation)'>{{ $params.patientRelative.relationshipOptions.find(o => o.value === item.relation).text}}</span>
                    </div>
                    <div v-if="item.dob"><span class="small text-gray-999">Năm sinh: </span>{{item.dob}}</div>
                    <div v-if="item.gender_id"><span class="small text-gray-999">Giới tính: </span>{{item.gender_id == 0 ? 'Nam' : item.gender_id == 1 ? 'Nữ' : 'Khác'}}</div>
                    <div ><span v-if='params.provinces[item.province_id]' class="small text-gray-999">Nơi sinh sống: </span>
                        <span v-if='params.wards[item.ward_id]' >{{params.wards[item.ward_id]}}, </span>
                        <span v-if='params.districts[item.district_id]' >{{params.districts[item.district_id]}}, </span>
                        <span v-if='params.provinces[item.province_id]' >{{params.provinces[item.province_id].name}}</span>
                    </div>
                </template>
                <template #cell(info)="{item}">
                    <div><span class="small text-gray-999">Giới tính: </span>{{item.gender_id == 0 ? 'Nam' : item.gender_id == 1 ? 'Nữ' : 'Khác'}}</div>
                    <div v-if="item.race_id" ><span class="small text-gray-999">Dân tộc: </span> 
                        <span v-if='params.race && params.race[item.race_id]'>{{params.race[item.race_id]}}</span>
                    </div>
                    <div ><span v-if='params.provinces[item.province_id]' class="small text-gray-999">Nơi sinh sống: </span>
                        <span v-if='params.wards[item.ward_id]' >{{params.wards[item.ward_id]}}, </span>
                        <span v-if='params.districts[item.district_id]' >{{params.districts[item.district_id]}}, </span>
                        <span v-if='params.provinces[item.province_id]' >{{params.provinces[item.province_id].name}}</span>
                    </div>
                </template>
                <template #cell(infos)="{item}">
                    <div v-if="params.stages[item.id]">
                        <div v-for="(item1, index) in params.stages[item.id]" :key="index" >
                            <i>- Giai đoạn từ ngày {{item1.start_time | dateFormat}} đến ngày <span v-if="item1.end_time > 0"> {{item1.end_time | dateFormat}} </span><span v-else>hiện tại</span>  đạt kết quả</i>: 
                            <span class='text-danger'>{{ $params.patient.resultOfEvaluation.find(o => o.value == item1.result) && item1.result ? $params.patient.resultOfEvaluation.find(o => o.value == item1.result).text : 'Chưa có KQ' }}</span>
                        </div>

                    </div>
                </template>
                <template #cell(action)="{item}">
                    <g-button v-if="permissions('patient-relative_view')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button v-if="permissions('patient-relative_update') && isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"  @click="update(item.id)" />
                    <g-button v-if="permissions('patient-relative_del') && isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    <br/>
                    <log type="patient-relative" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" size='xl' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                 <div class='col-md-4'>
                    <patient-select label="NKT được chăm sóc" :required='false' :model="formModel" :filter="{parent_id: $store.state.client.id}" attribute="patient_id" :errors="formErrors"></patient-select>
                </div>
                 <div class='col-md-4'>
                    <form-select label="Mối quan hệ với NKT" :required='false' :model="formModel" attribute="relation" :errors="formErrors" :options="$params.patientRelative.relationshipOptions" />
                </div>
                <div class='col-md'>
                    <form-input label="Mã số người thân" :required='true' :model="formModel" attribute="code"  :errors="formErrors" ></form-input>
                </div>
            </div> 
            <div class='row'>
                <div class='col-md-4'>
                    <form-input label="Họ và tên người thân" :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md-2'>
                    <form-input label="Năm sinh" :required='true' type="number" :model="formModel" attribute="dob"  :errors="formErrors" placeholder="YYYY"></form-input>
                </div>
                <div class='col-md-2'>
                    <form-select label="Giới tính" :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                <div class='col-md-4'>
                    <parameter-select label="Dân tộc" :type="$params.const.race.key"  :model="formModel" attribute="race_id" />
                </div>
            </div> 
            <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
            <stage label="Giai đoạn" :model="formModel" :errors="formErrors" attribute="items" />
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='xl' :title="formTitle" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                 <div class='col-md-4'>
                    <patient-select label="NKT được chăm sóc" :disabled='true' :required='false' :model="formModel" :filter="{parent_id: $store.state.client.id}" attribute="patient_id" :errors="formErrors"></patient-select>
                </div>
                 <div class='col-md-4'>
                    <form-select label="Mối quan hệ với NKT" :disabled='true' :required='false' :model="formModel" attribute="relation" :errors="formErrors" :options="$params.patientRelative.relationshipOptions" />
                </div>
                <div class='col-md-4'>
                    <form-input label="Mã số người thân" :disabled='true' :required='true' :model="formModel" attribute="code"  :errors="formErrors" ></form-input>
                </div>
            </div> 
            <div class='row'>
                <div class='col-md-4'>
                    <form-input label="Họ và tên người thân" :disabled='true' :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md-2'>
                    <form-input label="Năm sinh" :disabled='true' :required='true' type="number" :model="formModel" attribute="dob"  :errors="formErrors" placeholder="YYYY"></form-input>
                </div>
                <div class='col-md-2'>
                    <form-select label="Giới tính" :disabled='true' :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                <div class='col-md-4'>
                    <parameter-select label="Dân tộc" :disabled='true' :type="$params.const.race.key"  :model="formModel" attribute="race_id" />
                </div>
            </div> 
            <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true' :provinceDisabled='true' :districtDisabled='true' :wardDisabled='true'></form-group-address>
            <stage label="Giai đoạn" :model="formModel" :showFormView='showFormView' :errors="formErrors" attribute="items" />
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    import ClientSelect from '@/components/ClientSelect';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import ParameterSelect from '@/components/ParameterSelect';
    import PatientSelect from '@/components/PatientSelect';
    import Log from "@/components/Log";
    import Export from './PatientRelativeExport';
    import Import from './PatientRelativeImport';
    import Stage from './PatientRelativeStage';
    import ImportMinxi from "@/mixins/Template";

    export default {
        props: ["patientIdChild", "showFormPatient"],
        components: {ClientSelect, ProvinceSelect, ParameterSelect, Log, Dropdown, Import, Export, Stage, PatientSelect},
        mixins: [list, crud, role, ImportMinxi],
        data: function () {
            return {
                reloadBus: "reloadPatientRelative",
                service: '/patient-relative',
                defaultFilterFormModel: {
                    parameters: [
                        this.$params.const.race.key,
                    ],
                    keyword: '',
                    patient_id: 0,
                    patient_id_child: this.patientIdChild ? this.patientIdChild : 0,
                    client_id: this.isPrime() || this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                    relation: null,
                    type: null,
                    client_sub_id: null,
                },
                defaultFormModel: {
                    code: null,
                    race_id: null,
                    gender_id: null,
                    patient_id: this.patientIdChild ? this.patientIdChild : 0,
                    relation: null,
                    type: null,
                    province_id: null,
                    district_id: null,
                    ward_id: null,
                },
                showFormView: false,
                templateName: 'can-thiep-tam-ly-nguoi_than.xlsx',
                headers: [
                    {header: 'code', key: 'code', label: 'Mã số thành viên gia đình*', width: 26, desc: 'Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)'},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 17, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'dob', key: 'dob', label: 'Năm sinh*', width: 12, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 29, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 20, desc: "Nhập định dạng chữ"},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 20, desc: "Nhập định dạng chữ"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 16, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 12, desc: "Nhập định dạng chữ"},
                    {header: 'relation', key: 'relation', label: 'Mối quan hệ với NKT', width: 24, desc: "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: " + this.$params.patientRelative.relationshipOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    // {header: 'type', key: 'type', label: 'Loại hình hỗ trợ được nhận * ', width: 21},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu can thiệp/hỗ trợ*', width: 20, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu"},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày can thiệp gần nhất*', width: 20, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc can thiệp/hỗ trợ', width: 20, desc: "Nhập định dạng ngày/tháng/năm (dd/mm/yyyy)"},
                    {header: 'result', key: 'result', label: 'Kết quả đánh giá', width: 20, desc: "Nhập 01 giá trị số từ 1 đến 3 (Trong đó: " + this.$params.patient.resultOfEvaluation.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'patient_code', key: 'patient_code', label: 'Mã số NKT được chăm sóc', width: 22, desc: "Nhập định dạng chữ"},
                ],
                indexRow: 4,
            };
        },
        computed: {
            fields() {
                let fields = [
                    {key: 'fullname', label: "Người thân"},
                    {key: 'infos', label: "Giai đoạn"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if(!this.showFormPatient) {
                    if(this.isPrime() || this.isTW() || this.isUsaid()){
                        return ([
                            {key: 'client_id', label: "Đơn vị"},
                            {key: 'in', label: "NKT được chăm sóc"}
                        ].concat(fields));
                    } else {
                        return [{key: 'in', label: "NKT được chăm sóc"}].concat(fields);
                    }
                } else {
                    if (this.isPrime() || this.isTW() || this.isUsaid()) {
                        return ([{key: 'client_id', label: "Đơn vị"}].concat(fields));
                    }
                }
                return fields;
            },
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = val.toUpperCase();
                    }
                }, deep: true
            }
        },
        methods: {
            searchClient() {
                this.filterFormModel.client_sub_id = null;
                this.filterFormModel.page = 1;
                this.load();
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin người thân: " + this.formModel.fullname;
                    this.formModel.id = id;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
        }
    }
</script>