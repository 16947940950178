<template>
    <b-form-group :label="label" :invalid-feedback="errorTxt" :state="state" class='input-group-sm'>
        <template v-if="required" #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <multiselect v-model="selected" :custom-label="getLabel" track-by="id" :placeholder="placeHolder"
                     :options="items" :multiple="multiple" :close-on-select="closeOnSelect"
                     @input="onInput" @search-change="search"
                     select-label="Chọn" deselect-label="Xoá" selected-label="Đã chọn">
            <template v-slot:noResult>
                Không tìm thấy kết quả nào
            </template>
            <template v-slot:noOptions>
                Không tìm thấy kết quả nào
            </template>
        </multiselect>
        <template v-if="desc">
            <small v-if="desc" class="form-text text-muted">{{desc}}</small>
        </template>
    </b-form-group>
</template>

<script>
    import _ from "lodash";
    import list from '@/mixins/List';
    import Multiselect from 'vue-multiselect';

    export default {
        props: {
            model: Object,
            attribute: {
                type: String,
                default: 'client_id'
            },
            filter: Object,
            label: String,
            desc: String,
            errors: {},
            placeHolder: {
                type: String,
                default: "Chọn đơn vị"
            },
            multiple: Boolean,
            isFilter: Boolean,
            isOther: {
                type: Boolean,
                default: false
            },
            required: Boolean,
            closeOnSelect: {
                type: Boolean,
                default: true
            },
        },
        components: {Multiselect},
        mixins: [list],
        data() {
            return {
                selected: null,
                state: null,
                errorTxt: '',
                options: [],
                showList: false,
                service: '/client',
                autoLoad: false,
                defaultFilterFormModel: {
                    keyword: '',
                },
                focused: false,
                changeTrigger: true,
            }
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    if (val[this.attribute] && val[this.attribute] !== null) {
                        if (this.changeTrigger) {
                            this.loadSelected();
                        }
                        this.changeTrigger = true;
                    } else {
                        this.selected = null;
                    }
                }, deep: true
            },
            "filter.parent_id": {
                handler(val) {
                    if (val) {
                        this.load();
                    }
                }, deep: true
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        methods: {
            load() {
                let loadingDelay = _.delay(() => {
                    this.listLoading = true;
                }, 250);

                let filter = Object.assign({}, this.filterFormModel);
                if (this.filter) {
                    filter = Object.assign(this.filter, filter);
                }
                Object.keys(filter).forEach(key => {
                    if (typeof filter[key] === 'object' && filter[key]) {
                        filter[key] = filter[key].join(",");
                    }
                });
                this.$service.get(`${this.service}/index`, {
                    params: filter
                }).then(response => {
                    this.firstLoading = false;
                    this.apply(response.data);
                    if (this.isOther) {
                        this.items.push({
                            id: '-1',
                            code: "None",
                            name: "Khác"
                        });
                    }
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    clearTimeout(loadingDelay);
                    this.listLoading = false;
                });
            },
            onInput() {
                let val = this.selected;
                this.changeTrigger = false;
                if (val === null) {
                    this.model[this.attribute] = val;
                } else if (this.multiple) {
                    this.model[this.attribute] = val.map(s => s.id);
                } else {
                    this.model[this.attribute] = val.id;
                }
                this.$emit('change');
            },
            getLabel( {id, name}) {
                if (id === '-1') {
                    return 'Khác';
                }
                return name;
            },
            loadSelected() {
                if (this.model[this.attribute]) {
                    if (!this.multiple && this.isOther && this.model[this.attribute] === '-1') {
                        this.selected = {
                            id: '-1',
                            code: "None",
                            name: "Khác"
                        };
                    } else {
                        let ids;
                        if (this.multiple) {
                            ids = this.model[this.attribute].join(',');
                        } else {
                            ids = this.model[this.attribute];
                        }
                        if (ids) {
                            this.$service.get(`${this.service}/gets`, {
                                params: {ids: ids}
                            }).then(response => {
                                if (this.multiple) {
                                    this.selected = response.data;
                                } else if (response.data.length) {
                                    this.selected = response.data[0];
                                }
                            });
                        }
                    }
                }
            }
        },
        mounted() {
            this.selected = null;
            this.loadSelected();
            this.load();
        }
    }
</script>